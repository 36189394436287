/** @format */

import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import { HTMLContent } from "../components/Content"
import HyperLinkText from "../components/HyperLinkText"

export const MediaPageTemplate = ({ title }) => {
  return (
    <div>
      <section className="section">
        <div className="container">
          <div className="columns is-multiline is-centered">
            <div className="column is-8 is-6-desktop">
              <h2 className="mb-5 is-size-1 is-size-3-mobile has-text-weight-bold">
                {title}
              </h2>
              <p className="subtitle has-text-grey">
                Find links to blog posts and online news about the new Raleigh
                flag here.
              </p>
              <p className="subtitle has-text-grey mb-5">
                For media inquiries, contact us at{" "}
                <a href="mailto:media@newraleighflag.com?subject=New Raleigh Flag media inquiry">
                  media@newraleighflag.com
                </a>
                .
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="section mb-5">
        {[
          {
            publication: "ABC 11",
            articleTitle: "Group pushing for new official flag of Raleigh",
            articlePreview:
              "Some passionate people in Raleigh are pushing for the city to adopt a more modernized version of the official flag",
            articleLink:
              "https://abc11.com/official-flag-raleigh-group-pushing-new-modernized-design/15064195/",
            articleLinkText: "View story...",
            publicationImageSrc: "/img/WTVD-Logo-2021.png",
            publicationImageAlt: "WTVD-Logo-2021",
          },
          {
            publication: "The News & Observer",
            articleTitle:
              "New flag for Raleigh is bold, symbolic and not boring; backers hope it’s official",
            articlePreview:
              "A new flag being offered as Raleigh's offical symbol comes stamped with a sleek and majestic acorn – a bold, uncomplicated image that has long symbolized the City of Oaks.",
            articleLink:
              "https://eedition.newsobserver.com/shortcode/LMCRAL/edition/4570ff2e-ee9e-e5da-75cf-42f7cc84d0df?page=afc874d0-34e8-187c-7101-27586d42df5c",
            articleLinkText: "View story...",
            publicationImageSrc: "/img/news_and_observer_logo.png",
            publicationImageAlt: "news_and_observer_logo",
          },
          {
            publication: "Raleigh Forward",
            articleTitle: "A New Flag for Raleigh?",
            articlePreview:
              "The City’s official flag was introduced in 1960, but has not been updated since and is not widely displayed by Raleigh residents. A few years ago...",
            articleLink:
              "https://raleighforward.org/resident-engagement/a-new-flag-for-raleigh",
            articleLinkText: "View story...",
            publicationImageSrc: "/img/RaleighForwardLogo.jpg",
            publicationImageAlt: "RaleighForwardLogo",
          },
          {
            publication: "CBS 17",
            articleTitle:
              "‘A big community effort’: Grassroots movement creates new City of Raleigh flag; some businesses already flying it",
            articlePreview:
              "As you drive through Raleigh, you may notice several homes and businesses displaying a city flag with a different look.",
            articleLink:
              "https://www.cbs17.com/news/local-news/wake-county-news/a-big-community-effort-grassroots-movement-creates-new-city-of-raleigh-flag-some-businesses-already-flying-it/",
            articleLinkText: "View story...",
            publicationImageSrc: "/img/CBS17_logo.png",
            publicationImageAlt: "CBS17_logo",
          },
          {
            publication: "Spectrum News 1",
            articleTitle:
              "‘Bring people together’: Grassroots movement pushing for new official Raleigh flag",
            articlePreview:
              "It’s not common to see Raleigh’s flag flying around the city. Some people who live there say...​",
            articleLink:
              "https://spectrumlocalnews.com/nc/triangle-sandhills/news/2023/05/09/grassroots-movement-pushing-for-a-new-official-raleigh-flag",
            articleLinkText: "View story...",
            publicationImageSrc: "/img/spectrum_news_1.png",
            publicationImageAlt: "spectrum_news_1",
          },
          {
            publication: "RALtoday",
            articleTitle: "What's up with the new Raleigh flag?",
            articlePreview:
              "A local grassroots campaign is looking to make their new city flag design official.​",
            articleLink: "https://raltoday.6amcity.com/city/new-raleigh-flag",
            articleLinkText: "Continue reading...",
            publicationImageSrc: "/img/raltoday_logo.jpg",
            publicationImageAlt: "raltoday_logo",
          },
          {
            publication: "Raleigh Magazine",
            articleTitle: "New Wave",
            articlePreview: `"And the south wind blew hard on that ragged old flag.” Johnny Cash’s famed lyric...`,
            articleLink: "https://raleighmag.com/2023/03/raleigh-flag/",
            articleLinkText: "Continue reading...",
            publicationImageSrc: "/img/logo_raleigh_mag.png",
            publicationImageAlt: "logo_raleigh_mag",
          },
          {
            publication: "The Raleigh Connoisseur​",
            articleTitle:
              "[Guest Post] The New Raleigh Flag Campaign: Why It's Time for an Upgraded Flag​",
            articlePreview:
              "Hello, Raleigh! Since launching the New Raleigh Flag website a couple months ago we’ve received lots of questions...",
            articleLink:
              "https://dtraleigh.com/2022/09/guest-post-the-new-raleigh-flag-campaign-why-its-time-for-an-upgraded-flag/",
            articleLinkText: "Continue reading...",
            publicationImageSrc: "/img/logo_raleigh_connoisseur.jpg",
            publicationImageAlt: "logo_raleigh_connoisseur",
          },
          {
            publication: "Raleigh Philosophical Society",
            articleTitle: "A modern, updated flag for Raleigh​",
            articlePreview:
              "I'm a flag guy. I love checking out flags that fly from porches and homes...",
            articleLink:
              "http://raleighphilosociety.blogspot.com/2022/07/a-modern-updated-flag-for-raleigh.html",
            articleLinkText: "Continue reading...",
            publicationImageSrc: "/img/logo_ral_phil_society.jpg",
            publicationImageAlt: "logo_ral_phil_society",
          },
        ].map((article, index) => (
          <MediaArticle
            key={index}
            publication={article.publication}
            articleTitle={article.articleTitle}
            articlePreview={article.articlePreview}
            articleLink={article.articleLink}
            articleLinkText={article.articleLinkText}
            publicationImageSrc={article.publicationImageSrc}
            publicationImageAlt={article.publicationImageAlt}
          />
        ))}
      </section>
    </div>
  )
}

const MediaArticle = ({
  publication,
  articleTitle,
  articlePreview,
  articleLink,
  articleLinkText,
  publicationImageSrc,
  publicationImageAlt,
}) => {
  return (
    <div className="container">
      <div className="box mb-5">
        <div className="p-3 columns is-multiline is-vcentered">
          <div className="column is-12">
            <div className="is-vcentered columns is-multiline">
              <div className="column is-4 is-centered">
                <img
                  className="image is-fullwidth"
                  style={{ objectFit: "cover" }}
                  src={publicationImageSrc}
                  alt={publicationImageAlt}
                />
              </div>
              <div className="column is-8">
                <h2
                  className="is-size-3 has-text-weight-bold mb-4"
                  style={{
                    fontStyle: "italic",
                  }}
                >
                  {publication}
                </h2>
                <h4 className="title has-text-grey is-4">{articleTitle}</h4>
                <p className="subtitle has-text-grey">{articlePreview}</p>
                <HyperLinkText link={articleLink} text={articleLinkText} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

MediaPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

const MediaPage = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <MediaPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
      />
    </Layout>
  )
}

MediaPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default MediaPage

export const MediaPageQuery = graphql`
  query MediaPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
  }
`
